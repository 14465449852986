import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import "../pages/style.css";

export default function Impressum(props) {

  const [info, setInfo] = React.useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://p665936.mittwaldserver.info/api/api/singletons/get/ramp_seven?token=a8ccf49b48731963a832d84fe9e7d5",
        {
          method: "get",
          headers: { "Content-Type": "application/json" },
        }
      );
      await response.json().then(function (response) {
        setInfo(response);
      });
    };
    fetchData();
  }, []);

  if (!info) return null;


  return (
    <React.Fragment>
  <footer className="max-width">
        <div className="StickyFooter">
         
          <Link
            to="/de/datenschutz/"
            className="Btn Empty"
            alt="Datenschutz"
            title="Datenschutz"
          >
            Datenschutz
          </Link>
          <Link
            to="/de/impressum/"
            className="Btn Empty"
            alt="Impressum"
            title="Impressum"
          >
            Impressum
          </Link>
         
        </div>
      </footer>
    </React.Fragment>
  );
}
