import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./pages/style.css";

import Header from "./component/Header";
import Footer from "./component/Footer";

const DE = lazy(() => import("./pages/DE"));
const Datenschutz = lazy(() => import("./pages/Datenschutz"));
const Impressum = lazy(() => import("./pages/Impressum"));
const EN = lazy(() => import("./pages/EN"));


function App() {
  return (
    <React.Fragment>
      <Header />
      <div className="App">
        <Suspense fallback={<div className="loading loading-lg"></div>}>
          <Routes>
            <Route path="/" element={<Navigate to="/de/" />} />
            <Route path="/de/" element={<DE />} />
            <Route path="/de/datenschutz/" element={<Datenschutz />} />
            <Route path="/de/impressum/" element={<Impressum />} />
            <Route path="/en/" element={<EN />} />
          </Routes>
        </Suspense>
      </div>
      <Footer/>
    </React.Fragment>
  );
}

export default App;
